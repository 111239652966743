/** @jsx jsx */
import { jsx } from 'theme-ui'

import { prettyCategory } from '../utils/helpers'

const Hero = (props) => {
    const {
        resultsNumber,
        canton,
        category,
        tag
    } = props

    // console.log('PREttY CATEGOrY for category', category, " is ", prettyCategory(category))

    const gradient = {
        background: theme => `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    }

    return (
        <div sx={{py: 5}}>
            <h1
                sx={{
                    fontSize: [6, 7, 8],
                    lineHeight: 1,
                    m: 0, 
                    fontFamily: 'body', fontWeight: 'heading',
                }}
            >

                {resultsNumber !== null && resultsNumber}{` `}

                {category && <span sx={gradient}>
                    {resultsNumber !== null && resultsNumber > 1 ? prettyCategory(category)[1] : prettyCategory(category)[0]}
                </span>}

                {tag && <span sx={gradient}>{tag}</span>}
                
                {` `}

                {canton ? `dans le ` : `en ` }

                <span sx={{
                    color: 'grey'
                }}>
                    {canton || `Suisse romande` }
                </span>
            </h1>
        </div>
    )
}

export default Hero